import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import { SecurityStaticData } from '../../../store/securities/reducer'

import styles from './DepthOfMarket.module.scss'

interface Props {
  security: SecurityStaticData
  footer: ReactNode
}

const InlineDepthHeader = ({ security, footer }: Props) => {
  const displayDate = dayjs(security.maturityDate).format('MM/YY')
  const identifier = security.cusip || security.isin || ''

  return (
    <div className={styles.inlineHeader}>
      <p>{`${security.issuerSymbol} ${security.coupon}  ${displayDate} | ${identifier}`}</p>
      {footer}
    </div>
  )
}

export default InlineDepthHeader
