import {
  faHome,
  faUser
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import {
  faBars,
  faOctagon
} from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { noop } from 'rxjs'
import { useOpenFin } from '../../app/openFinContext'
import bondsLogoLightTheme from '../../assets/images/bonds_logo_black.png'
import bondsLogoDarkTheme from '../../assets/images/logo_white_blue.png'
import config from '../../config'
import { getIsSignedIn, getUserName } from '../../store/auth/selectors'
import { getMarketClosed } from '../../store/market/selectors'
import { getUseLightTheme } from '../../store/userPreferences/selectors'
import { cancelAllWatchListOrdersAction } from '../../store/watchList/actions'
import BenchmarkContainer from '../BenchmarkData/BenchmarkContainer'
import MarketsClosed from '../MarketsClosed/MarketsClosed'
import UserSelector from '../UserSelector/UserSelector'
import styles from './header.module.scss'

import { useAppSelector } from '../../store'
import AdminMenu from './Menus/AdminMenu'
import ClientMenu from './Menus/ClientMenu'

export interface Props {
  setSelectedTab: (st: string | null) => void
  isAdmin: boolean
}

const Header: FC<Props> = ({ setSelectedTab, isAdmin }) => {
  const { fin } = useOpenFin()
  const isSignedIn = useAppSelector(getIsSignedIn)
  const userName = useAppSelector(getUserName)
  const marketClosed = useAppSelector(getMarketClosed)
  const useLightTheme = useAppSelector(getUseLightTheme)

  const dispatch = useDispatch()

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(0, undefined))
  }

  const bondsLogoTheme = useLightTheme
    ? bondsLogoLightTheme
    : bondsLogoDarkTheme

  return (
    <div className={styles.header}>
      <a href="http://www.bonds.com" target="_blank" rel="noopener noreferrer">
        <img alt="bonds.com" src={bondsLogoTheme} style={{ height: '4rem' }} />
      </a>
      {!marketClosed && !fin && !isAdmin && isSignedIn && (
        <BenchmarkContainer />
      )}
      {marketClosed && (
        <MarketsClosed className={styles.marketStatus} isAdmin={isAdmin} />
      )}
      {isAdmin && <UserSelector />}
      <span className={styles.marketStatus}>{config.env}</span>
      {isSignedIn && (
        <div className={styles.rightSection}>
          <div className={styles.navTitle}>
            <span data-testid="email" className={styles.navIcon}>
              {userName}
            </span>
          </div>

          <div className={styles.navTitle} onClick={cancelAllOrders}>
            <div title="Cancel All Orders">
              <FontAwesomeIcon
                className={cx(styles.navIcon, styles.cancelAllButton)}
                icon={faOctagon}
              />
            </div>
          </div>

          <div
            className={styles.navTitle}
            onClick={() => setSelectedTab('Watchlist')}
          >
            <div title="Home">
              <FontAwesomeIcon className={styles.navIcon} icon={faHome} />
            </div>
          </div>
          {isAdmin && (
            <div className={styles.navTitle}>
              <FontAwesomeIcon className={styles.navIcon} icon={faUser} />
              <div className={styles.dropdownMenuWrapper}>
                <AdminMenu closeMenu={noop} handleNavigation={setSelectedTab} />
              </div>
            </div>
          )}

          <div className={styles.navTitle}>
            <FontAwesomeIcon className={styles.navIcon} icon={faBars} />
            <div className={styles.dropdownMenuWrapper}>
              <ClientMenu closeMenu={noop} handleNavigation={setSelectedTab} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
