interface WindowConfig {
  windowHeight: number
  windowWidth: number
  isAdminWindow: boolean
}

export const openfinConfig: Record<string, WindowConfig> = {
  ActivityBlotter: {
    windowHeight: 500,
    windowWidth: 500,
    isAdminWindow: false
  },
  ActivityLog: {
    windowHeight: 300,
    windowWidth: 800,
    isAdminWindow: false
  },
  Aggressor: {
    windowHeight: 458,
    windowWidth: 400,
    isAdminWindow: false
  },
  AlertManager: {
    windowHeight: 643,
    windowWidth: 431,
    isAdminWindow: false
  },
  Benchmarks: {
    windowHeight: 100,
    windowWidth: 800,
    isAdminWindow: false
  },
  Depth: {
    windowHeight: 340,
    windowWidth: 543,
    isAdminWindow: false
  },
  LastLook: {
    windowHeight: 458,
    windowWidth: 400,
    isAdminWindow: false
  },
  PassiveOrders: {
    windowHeight: 333,
    windowWidth: 1030,
    isAdminWindow: false
  },
  Settings: {
    windowHeight: 500,
    windowWidth: 340,
    isAdminWindow: false
  },
  Watchlist: {
    windowHeight: 500,
    windowWidth: 650,
    isAdminWindow: false
  },
  // ADMIN WINDOWS
  ActivityTicker: {
    windowHeight: 300,
    windowWidth: 1090,
    isAdminWindow: true
  },
  AggressAttempts: {
    windowHeight: 333,
    windowWidth: 1030,
    isAdminWindow: true
  },
  APIUserData: {
    windowHeight: 530,
    windowWidth: 1030,
    isAdminWindow: true
  },
  BBMISIN: {
    windowHeight: 610,
    windowWidth: 750,
    isAdminWindow: true
  },
  ExternalLiquidityStats: {
    windowHeight: 530,
    windowWidth: 1030,
    isAdminWindow: true
  },
  GUIUserData: {
    windowHeight: 530,
    windowWidth: 1030,
    isAdminWindow: true
  },
  ManagementReports: {
    windowHeight: 355,
    windowWidth: 415,
    isAdminWindow: true
  },
  QuerySelector: {
    windowHeight: 600,
    windowWidth: 740,
    isAdminWindow: true
  },
  QueueStats: {
    windowHeight: 225,
    windowWidth: 735,
    isAdminWindow: true
  },
  RestrictedTrading: {
    windowHeight: 300,
    windowWidth: 700,
    isAdminWindow: true
  },
  RestrictedTradingPermanent: {
    windowHeight: 300,
    windowWidth: 700,
    isAdminWindow: true
  },
  SystemParameters: {
    windowHeight: 450,
    windowWidth: 1050,
    isAdminWindow: true
  },
  Tiers: {
    windowHeight: 550,
    windowWidth: 750,
    isAdminWindow: true
  },
  TradeBlotter: {
    windowHeight: 300,
    windowWidth: 900,
    isAdminWindow: true
  },
  UserDashboard: {
    windowHeight: 275,
    windowWidth: 900,
    isAdminWindow: true
  },
  UserEditor: {
    windowHeight: 660,
    windowWidth: 800,
    isAdminWindow: true
  },
  WatchlistManager: {
    windowHeight: 550,
    windowWidth: 750,
    isAdminWindow: true
  }
}

export const copyAppStyles = (window: Window) => {
  // Copy the app's styles into the new window
  const stylesheets = Array.from(document.styleSheets)
  stylesheets.forEach((stylesheet) => {
    /* tslint:disable */
    const css = stylesheet
    if (stylesheet.href) {
      const newStyleElement = document.createElement('link')
      newStyleElement.rel = 'stylesheet'
      newStyleElement.href = stylesheet.href
      window.document.head.appendChild(newStyleElement)
    } else if (css && css.cssRules && css.cssRules.length > 0) {
      const newStyleElement = document.createElement('style')
      Array.from(css.cssRules).forEach((rule) => {
        newStyleElement.appendChild(document.createTextNode(rule.cssText))
      })
      window.document.head.appendChild(newStyleElement)
    }
  })

  // Copy the theme class from the body element
  const originalBody = document.body
  const newBody = window.document.body

  newBody.className = originalBody.className
}
