import { createContext, useCallback, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { openfinConfig } from '../containers/Openfin/helpers'
import {
  getMarketClosed,
  getMarketClosedTimer
} from '../store/market/selectors'
import { getIsAdmin } from '../store/webSettings/selectors'
import { useAppDispatch } from '../store'
import { closePopout } from '../store/depthOfMarket/actions'

interface WindowAction {
  close(): void
  hide(): void
  minimize(): void
  restore(): void
  show(): void
}

// TODO: write or get some types for this
export const OpenFinContext = createContext<any>(undefined)

export const useOpenFin = () => {
  const dispatch = useAppDispatch()
  const fin = useContext(OpenFinContext)
  const mainWindow = fin?.desktop.Window.getCurrent()
  const allWindows = fin?.desktop.Application.getCurrent()
  const marketClosed = useSelector(getMarketClosed)
  const marketClosedTimer = useSelector(getMarketClosedTimer)
  const isAdmin = useSelector(getIsAdmin)

  const restoreWindow = (window: any) => {
    window.restore()
    window.setAsForeground()
  }

  // ------------------------TEMPORARY FUNCTIONS------------------------ //
  // Hiding windows instead of closing them is a bandaid to prevent
  // closed windows from cancelling a user's orders.
  // This cleans up hidden/closed windows before log out

  const checkVisibility = (win: any) => {
    fin.desktop.System.getAllWindows((windowInfoList: any) => {
      windowInfoList.forEach((windowInfo: any) => {
        if (mainWindow.uuid === windowInfo.uuid) {
          windowInfo.childWindows.forEach((childWindow: any) => {
            if (
              childWindow.name === win.name &&
              childWindow.state === 'minimized'
            ) {
              restoreWindow(win)
            }
          })
        }
      })
    })
  }

  const closeHiddenWindows = () => {
    fin.desktop.System.getAllWindows((windowInfoList: any) => {
      windowInfoList.forEach((windowInfo: any) => {
        if (mainWindow.uuid === windowInfo.uuid) {
          windowInfo.childWindows.forEach((childWindow: any) => {
            if (!childWindow.isShowing && childWindow.state === 'normal') {
              if (childWindow.name.includes('Depth')) {
                const id = childWindow.name.split('/')[0]
                dispatch(closePopout(Number(id)))
              }
              manageWindows(childWindow.name, 'close')
            }
          })
        }
      })
    })
  }

  // ------------------------WINDOW DIMENSIONS------------------------ //
  const changeWidth = (currentWindow: any, width: number) => {
    currentWindow.updateOptions({
      maxWidth: width
    })
    currentWindow.getBounds((bounds: any) => {
      currentWindow.resizeTo(width, bounds.height)
    })
  }

  const updateWindowDimensions = useCallback(
    (name: string, width: number) => {
      if (name === 'BondList') {
        changeWidth(mainWindow, width)
      } else {
        allWindows.getChildWindows((children: any) => {
          children.find(
            (child: any) => child.name === name && changeWidth(child, width)
          )
        })
      }
    },
    [allWindows]
  )

  // ------------------------WINDOW CONTROLS------------------------ //
  const setWindowsInLocalStorage = () => {
    const windowList: string[] = []
    allWindows.getChildWindows((children: any) => {
      children.forEach((child: any) => {
        windowList.push(child.name)
      })
      windowList.length > 0
        ? localStorage.setItem('openfinOpenWindows', windowList.toString())
        : localStorage.removeItem('openfinOpenWindows')
    })
  }

  const manageWindows = (target: string | null, action: keyof WindowAction) => {
    allWindows.getChildWindows((children: any) => {
      children.forEach((childWindow: any) => {
        if (!target || childWindow.name.startsWith(target)) {
          if (action === 'show') {
            // special case for depths to reopen when
            // popout button is clicked
            restoreWindow(childWindow)
          }
          if (action === 'restore') {
            checkVisibility(childWindow)
            return
          }
          childWindow[action]?.()
          if (action === 'close') {
            setWindowsInLocalStorage()
          }
        }
      })
    })
  }

  const toggleFrame = (frame: boolean) => {
    mainWindow.updateOptions({
      frame
    })
  }

  useEffect(() => {
    // on nightly log out, add frame back and hide child windows
    if (fin && !isAdmin && marketClosed && marketClosedTimer === 0) {
      toggleFrame(true)
      closeHiddenWindows()
      manageWindows(null, 'hide')
    }
  }, [marketClosedTimer])

  // ------------------------CREATE WINDOWS------------------------ //

  const createOpenfinWindow = async (
    name: string,
    windowLeft: number,
    windowTop: number,
    frame: boolean = false,
    resizable: boolean = true
  ) => {
    // baseName is for windows that need a route parameter(depth/aggressor/last look)
    const baseName =
      name === 'WatchlistManager'
        ? undefined
        : Object.keys(openfinConfig).find((key) => name.includes(key))

    const {
      windowHeight = 500,
      windowWidth = 500,
      isAdminWindow = false
    } = openfinConfig[baseName ?? name] || {}

    const winOption = {
      name,
      defaultWidth: windowWidth,
      defaultHeight: windowHeight,
      defaultLeft: windowLeft,
      defaultTop: windowTop,
      url: `/${isAdminWindow ? 'admin/' : ''}${name}`,
      frame,
      saveWindowState: true,
      contextMenu: true,
      maximizable: false,
      autoShow: true,
      showTaskbarIcon: true,
      cornerRounding: {
        height: 10,
        width: 10
      },
      resizable
    }
    const newWindow = await fin.Window.create(winOption)
    newWindow.on('closed', () => {
      setWindowsInLocalStorage()
    })
    setWindowsInLocalStorage()
    return newWindow
  }

  const renderWindowCheck = (name: string) => {
    allWindows.getChildWindows((children: any) => {
      const focusedWindow = children.find((child: any) => child.name === name)
      focusedWindow
        ? restoreWindow(focusedWindow)
        : fin.desktop.System.getMousePosition((mousePosition: any) => {
            createOpenfinWindow(
              name,
              mousePosition.left,
              mousePosition.top
            ).then((win) => {
              win.setAsForeground()
            })
          })
    })
  }

  // ------------------------AUTOLAUNCH------------------------ //

  const autoLaunch = (
    startupParameters: any,
    interval: any,
    setStateInterval: any
  ) => {
    if (startupParameters.openfinAutoStart) {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
      setStateInterval(
        setInterval(() => {
          const now = new Date()
          const hour = now.getHours()
          const min = now.getMinutes()
          const sec = now.getSeconds()
          const startTime = startupParameters.openfinStartTime
          let startHour: number | undefined
          let startMin: number | undefined
          if (startTime) {
            const startTimeArr = startTime.split(':')
            if (startTimeArr.length === 2) {
              startHour = Number(startTimeArr[0])
              startMin = Number(startTimeArr[1])
            }
          }
          if (
            startHour &&
            startMin &&
            hour === startHour &&
            sec < 2 &&
            min === startMin
          ) {
            mainWindow.restore()
            manageWindows(null, 'restore')
          }
        }, 1000)
      )
    } else {
      if (interval) {
        clearInterval(interval)
        setStateInterval(undefined)
      }
    }
  }

  return {
    fin,
    autoLaunch,
    closeHiddenWindows,
    createOpenfinWindow,
    manageWindows,
    toggleFrame,
    renderWindowCheck,
    updateWindowDimensions
  }
}
