import cx from 'classnames'
import React, {
  ComponentRef,
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import { useOpenFin } from '../../app/openFinContext'
import AdvancedFilterEditor from '../../components/AdvancedFilter/AdvancedFilterEditor'
import CheckedSecuritiesActions from '../../components/CheckedSecuritiesActions/CheckedSecuritiesActions'
import CookieNotice from '../../components/CookieNotice/CookieNotice'
import Grid from '../../components/Grid/Grid'
import MineGrid from '../../components/Grid/MineGrid'
import LeftMenu from '../../components/Header/Menus/LeftMenu'
import { useAppSelector } from '../../store'
import {
  popoutSecurity,
  selectSecurity
} from '../../store/depthOfMarket/actions'
import { setMyOrdersOpen } from '../../store/order/actions'
import { isMyOrdersOpen } from '../../store/order/selectors'
import { setIsMine, showSecurityDetails } from '../../store/securities/actions'
import {
  getIsMine,
  getQuoteReliability,
  getSelectedSecurityId,
  getWatchlistId
} from '../../store/securities/selectors'
import { fetchWatchlistDetails } from '../../store/watchList/actions'
import { getDetailsForCurrentWatchlist } from '../../store/watchList/selectors'
import {
  getCanEnterPassiveOrders,
  getHasTradingRights
} from '../../store/webSettings/selectors'
import SecurityDetailsModalLazy from '../DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'
import Titlebar from '../Openfin/Titlebar/Titlebar'
import BondListHeader from './BondListHeader'
import styles from './bondListStyle.module.scss'

export interface Props {
  gridIndex: number
}

const BondList: FC<Props> = ({ gridIndex }) => {
  const dispatch = useDispatch()
  const myOrdersOpen = useAppSelector(isMyOrdersOpen)(gridIndex)
  const quoteReliability = useAppSelector(getQuoteReliability)(gridIndex)
  const hasTradingRights = useAppSelector(getHasTradingRights)
  const canEnterPassiveOrders = useAppSelector(getCanEnterPassiveOrders)
  const isMine = useAppSelector(getIsMine)(gridIndex)
  const watchlistId = useAppSelector(getWatchlistId)(gridIndex)
  const watchlistDetails = useAppSelector(getDetailsForCurrentWatchlist)(
    gridIndex
  )
  const selectedSecurityId = useAppSelector(getSelectedSecurityId)(gridIndex)
  const [menu, setMenu] = useState('')

  const [isDestroyed, setIsDestroyed] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [isMineState, setIsMineState] = useState(isMine)
  const { ref, width } = useResizeObserver<HTMLDivElement>()
  const lastWidthRef = useRef<number | undefined>()
  const windowName = gridIndex === 0 ? 'BondList' : `Watchlist/${gridIndex}`
  const { fin, updateWindowDimensions } = useOpenFin()

  useEffect(() => {
    if (
      (!hasTradingRights || !canEnterPassiveOrders) &&
      (myOrdersOpen || isMine)
    ) {
      dispatch(setMyOrdersOpen(gridIndex, false))
      dispatch(setIsMine(gridIndex, false))
    }
  }, [])

  useEffect(() => {
    if (width && width !== lastWidthRef.current) {
      lastWidthRef.current = width
    }
  }, [width])

  const canEditWatchlist =
    watchlistDetails !== undefined && watchlistDetails.canEdit

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
    } else {
      setIsDestroyed(true)
    }
  }, [isMine])

  useEffect(() => {
    if (isDestroyed) {
      setIsMineState(isMine)
      setIsDestroyed(false)
    }
  }, [isDestroyed])

  useEffect(() => {
    if (watchlistId && !watchlistDetails) {
      dispatch(fetchWatchlistDetails(watchlistId, gridIndex))
    }
  }, [watchlistId, watchlistDetails])

  const onSelectionChanged = useCallback(
    (securityId?: number) => {
      if (securityId) {
        dispatch(selectSecurity(gridIndex, securityId, quoteReliability))
      }
    },
    [quoteReliability]
  )

  const onRowDoubleClicked = useCallback((securityId) => {
    if (securityId) {
      dispatch(popoutSecurity(securityId))
      // So there is no selected text after double click:
      window.getSelection?.()?.removeAllRanges()
    }
  }, [])

  const closeSecurityDetails = useCallback(() => {
    dispatch(showSecurityDetails(gridIndex, undefined))
  }, [])

  const toggleMyOrders = useCallback(() => {
    if (hasTradingRights && canEnterPassiveOrders) {
      dispatch(setMyOrdersOpen(gridIndex, !myOrdersOpen))
    }
  }, [myOrdersOpen])

  const classNameContent = myOrdersOpen ? styles.expandable : styles.collapsed

  const [advancedFilterActive, setAdvancedFilterActive] = useState(false)

  // ------------------ collapsing grid rows ------------------ //

  const [showCollapseRows, setShowCollapseRows] = useState(false)
  const collapseRef = useRef<ComponentRef<typeof Grid>>(null)

  const collapseAllRows = useCallback(() => {
    if (!collapseRef.current) return
    collapseRef.current.collapseRows()
  }, [collapseRef.current?.collapseRows])

  const getGrid = () => {
    return isMineState === isMine ? (
      isMine ? (
        <MineGrid
          ref={collapseRef}
          gridIndex={gridIndex}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
          canEditWatchlist={canEditWatchlist}
          setRowsAreOpen={setShowCollapseRows}
        />
      ) : (
        <Grid
          ref={collapseRef}
          gridIndex={gridIndex}
          onSelectionChanged={onSelectionChanged}
          onRowDoubleClicked={onRowDoubleClicked}
          canEditWatchlist={canEditWatchlist}
          watchlistName={watchlistDetails?.name}
          setRowsAreOpen={setShowCollapseRows}
        />
      )
    ) : (
      <div />
    )
  }

  useEffect(() => {
    fin && width && updateWindowDimensions(windowName, width)
  }, [menu, width, myOrdersOpen])

  const toggleMenu = (menuName: string) => {
    menu === menuName ? closeMenu() : setMenu(menuName)
  }

  const closeMenu = () => {
    setMenu('')
  }

  return (
    <div style={{ display: 'flex', height: '100%' }} ref={fin ? ref : null}>
      {menu && <LeftMenu menuName={menu} closeMenu={closeMenu} />}
      <div className={cx(styles.gridContainer, classNameContent)}>
        {fin && gridIndex === 0 && <Titlebar toggleMenu={toggleMenu} />}
        <BondListHeader
          gridIndex={gridIndex}
          toggleMyOrders={toggleMyOrders}
          myOrdersOpen={myOrdersOpen}
          advancedFilterActive={advancedFilterActive}
          setAdvancedFilterActive={setAdvancedFilterActive}
          canEditWatchList={watchlistDetails?.canEdit ?? false}
          showCollapseRows={showCollapseRows}
          collapseRows={collapseAllRows}
        />
        {advancedFilterActive && (
          <AdvancedFilterEditor
            active={advancedFilterActive}
            setActive={setAdvancedFilterActive}
            gridIndex={gridIndex}
            myOrdersOpen={myOrdersOpen}
          />
        )}
        {getGrid()}
        <CheckedSecuritiesActions gridIndex={gridIndex} />
        <SecurityDetailsModalLazy
          securityId={selectedSecurityId ?? 0}
          isOpen={!!selectedSecurityId}
          toggleIsOpen={closeSecurityDetails}
        />
        {fin && <CookieNotice />}
      </div>
    </div>
  )
}

export default memo(BondList)
